import SlickCarouselBase from 'SlickCarouselBase';

/**
 * Creates a Comparison Carousel.
 *
 * @requires lodash
 * @extends module:project/Common.SlickCarouselBase
 * @memberof module:feature/Teasers
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class ComparisonCarousel extends SlickCarouselBase {
    /**
     * Adds element to create carousel in.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     */
    constructor($element) {
        super($element);
    }

    /**
     * Initializes carousel.
     */
    init() {
        const numberOfSlides = this.$element.find('.comparison-carousel__item').length;

        // create config to override/extend
        const config = {
            slidesToShow: numberOfSlides > 2 ? 3 : numberOfSlides,
            centerPadding: '150px',
            speed: 600,
            prevArrow: `<button type="button" class="comparison-carousel__btn comparison-carousel__btn--prev">
                        <span class= "icon icon--chevron-left" >
                            <svg role="img" title="icon: chevron left">
                                <use xlink: ="" href="/assets/icons/common/ui.svg#chevron-left"></use>
                            </svg>
                        </span>
                    </button>`,
            nextArrow: `<button type="button" class="comparison-carousel__btn comparison-carousel__btn--next">
                        <span class= "icon icon--chevron-right" >
                            <svg role="img" title="icon: chevron right">
                                <use xlink: ="" href="/assets/icons/common/ui.svg#chevron-right"></use>
                            </svg>
                        </span>
                    </button>`,
            responsive: [
                {
                    breakpoint: 50000,
                    settings: {
                        slidesToShow: 3,
                        centerPadding: '75px',
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: '50px',
                    },
                },
                {
                    breakpoint: 786,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: '25px',
                    },
                },
                {
                    breakpoint: 576,
                    settings: 'unslick',
                },
            ],
        };

        // init event of slick
        this.$element.on('init', (e, slick) => {
            if (viewport.is('>=sm') && slick.slideCount > 3) {
                this.activateItems();
            }
        });

        // after change event of slick
        this.$element.on('afterChange', (e, slick) => {
            if (viewport.is('>=sm') && slick.slideCount > 3) {
                this.activateItems();
            }
        });

        // init carousel
        super.init(config);
    }

    /**
     * Activates current, previous and next items.
     */
    activateItems() {
        const slickItemClass = '.comparison-carousel__item';
        const $slickItem = this.$element.find(slickItemClass);
        const inactive = 'is-inactive';
        $slickItem.addClass(inactive);
        this.$element.find('.slick-current ' + slickItemClass).removeClass(inactive);
        this.$element.find('.slick-current').prev().find(slickItemClass).removeClass(inactive);
        this.$element.find('.slick-current').next().find(slickItemClass).removeClass(inactive);
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: ComparisonCarousel,
    classAsString: 'ComparisonCarousel',
    cssClass: 'js-comparison-carousel',
});

export default ComparisonCarousel;
