import lodash from 'lodash';
import 'slick-carousel/slick/slick.js';

/**
 * Carousel class based on slick JS.
 *
 * @requires lodash
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class SlickCarouselBase {
    /**
     * constructor
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $($element);
        this.carousel = null;

        this.config = {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: true,
            centerPadding: '0',
            focusOnSelect: false,
            infinite: false,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
    }

    /**
     * Initialize.
     * @param {Object} config Slick Configuration Object.
     */
    init(config) {
        // extend/overwrite config
        if (config) {
            this.config = $.extend(this.config, config);
        }

        // init slick carousel
        this.$element.slick(this.config);

        // add/remove eventlistener to reinit slick after "unslick"
        this.$element.on('destroy', (event, slick) => {
            let reinit = lodash.debounce(() => {
                if (!this.$element.hasClass('slick-initialized')) {
                    this.carousel = this.$element.slick(slick.options);
                }
                window.removeEventListener('resize', reinit);
            }, 500);
            $(window).on('resize', reinit);
        });
    }
}

export default SlickCarouselBase;
